import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Codeblock from '../common/Codeblock'

class WhyIntegrate extends React.Component {

   render() {
     return (
      <section id="what" data-stellar-background-ratio="0.5">
        <Container>
            <Row>
                  <Col>
                      <div className="section-title">
                            <h1>Why Integrate - Common Use Cases</h1>
                      </div>

                  </Col>
            </Row>
            <Row>
                  <Col>
                  <p>
                            Integration is effective: save time, money and resources. Connect your existing customer data to our survey tool or integrate results directly to your systems. Automate your survey sendings and get even more feedback from your customers.
                      </p>
                      <p>
                            API Integration benefits include
                            <ul>
                                  <li>reduce manual work</li>
                                  <li>optimize your processes</li>
                                  <li>expand the reach of your data</li>
                            </ul>
                      </p>
                  </Col>

            </Row>
            <Row>
                  <Col md={6}>
                      <h2>Automate creation of your surveys</h2>
                            <p>Create a survey or an event directly from your system.</p>
                      <h2>Automate sending of your surveys</h2>
                            <p>Send surveys and events to your recipients from your CRM or other directory.</p>
                      <h2>Fetch your survey responses automatically to your systems</h2>
                            <p>Get responses or reports and integrate to any of your reporting systems from PowerBI to CRM etc.</p>
                        <h2>How do I start?</h2>
                        <p>Read more from the <a href="/docs">Docs section</a>.</p>
                  </Col>
                  <Col md={6}>

                    <Codeblock codeName="createEvent"/>
                  </Col>

            </Row>
        </Container>
      </section>
     );
   }
 }

 export default WhyIntegrate