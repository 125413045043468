import React from 'react';
import { useState, useEffect }  from 'react';

import { Container, Col, Row } from 'react-bootstrap';
import Header from './common/Header'
import LeftNav from './docs/LeftNav'
import Footer from './common/Footer'
import terminology from "./docs/data/terminology.json"
import schema from './docs/data/schema';
import TermTable from './common/TermTable';

function Docs () {

  const [ data, setData] = useState(null);

  useEffect(() => {
    new Promise((resolve) => {
        setTimeout(() => {
            resolve(terminology);
        }, 2000)
    }).then((result) => {
        setData(result);
    })
  });
    return (
        <div className="docs">
         <Header/>
          <Container>
             <Row>
               <Col md={3} className="left-nav">
                  <LeftNav/>
               </Col>
             <Col md={9} className="content">
             <div className="table-wrapper">
              <TermTable headers={Object.keys(schema)} rows={data} />
             </div>
             </Col>
             </Row>
          </Container>
        <Footer page="docs"/>
        </div>

    );
  }


export default Docs;
