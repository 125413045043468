import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Header from '../components/common/Header'
import AppList from '../components/status/AppList'
import Footer from '../components/common/Footer'

class Status extends React.Component {

  render() {
    return (
        <div className="status">
          <Header />
            <Container>
              <section className="status-info">
                <Row >
                  <Col >
                    <h2 className="section-heading">Webropol Application Status</h2>
                    <p  className="d-flex justify-content-center">This page provides information on how our systems are doing. If any interruptions occur, you will notice it from here, if systems are on green, everything is running smoothly.</p>
                    <p>If any issues, please contact our helpdesk for support <a href="mailto:helpdesk@webropol.com">helpdesk@webropol.com</a>.</p>
                  </Col>
                </Row>
              </section>
              <AppList />
            </Container>
            <Footer page="status"/>
          </div>


    );
  }

}

export default Status;
