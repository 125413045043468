import React from 'react';
import { faqs } from '../docs/data/faqs';

class Faq extends React.Component {

   render() {
     return (
      <article className="docs-article" id="faq">
        <header className="docs-header">
          <h1 className="docs-header">FAQ</h1>
        </header>
        <div>
                {faqs.map((faq, i) => (
                        <div
                        key={i}
                        className="faq-item"
                        id={faq.id}
                        aria-label={faq.id ? faq.id : ""}
                        aria-hidden={faq.id ? "false" : "true"}
                        >
                            <h2>{faq.title}</h2>
                            <p>{faq.answer}</p>
                        </div>
                    ))
                }
            </div>
      </article>
     );
   }
 }

 export default Faq;