import React from 'react';
import Codeblock from '../common/Codeblock';
import { Image } from 'react-bootstrap'

class GettingStarted extends React.Component {


   render() {
     return (
       <div>
      <section className="docs-section" id="getting-started">
      <h2 className="section-heading">Getting Started</h2>
      <p>
         To be able to utilize our API, you will need to have a valid licence for Webropol and the API extension enabled. To enable the API, please contact our sales department and we will get you started in no time! However, make sure to read this section first!
       </p>
       <div className="callout-block callout-block-success">
         <div className="content">
             <h3 className="callout-title must-read">
                <span className="callout-icon-holder mr-1">

               </span>
               🛎️🛎️🛎️ This is the part you actually need to read! 🛎️🛎️🛎️
            </h3>
       <h4>1. Check  out our example requests and responses</h4>
       <p>There are several ways to get started with playing around with our API. We highly recommend checking out the example requests and responses as that documentation contains tons of extra information.</p>
            <p><a target="_blank" href="https://documenter.getpostman.com/view/6929240/TVejhqBv#intro">Show me the examples!</a></p>
       <h4>2. Import the full collection to Postman</h4>
       <p>From the <a target="_blank" href="https://documenter.getpostman.com/view/6929240/TVejhqBv#intro">example collection</a>, on the top right you will see the button Run in Postman - click and follow the instructions to import the collection directly to your local Postman installation</p>
       <h4>3. Import the collection manually from Swagger/OpenAPI documentation</h4>
       <p>
         You can also import the request endpoints with the Swagger/OpenAPI documentation. Click the Import button in Postman (or your preferred software that supports OpenAPI imports), select Link and enter this url and continue <a href="https://rest.webropolsurveys.com/swagger/docs/v1" target="_blank">https://rest.webropolsurveys.com/swagger/docs/v1</a>.
       </p>
       <p>After importing, you will have all the requests available at your hand, you'll now be able to edit the requests and organize them to match your preferences.</p>
       <h4>4. Learn how to authenticate</h4>
       <p>Authentication is explained on this page <a href="#authentication">below</a> and in the <a target="_blank" href="https://documenter.getpostman.com/view/6929240/TVejhqBv#intro">example collection</a></p>
       </div>
       </div>


       <h2 className="section-heading">
         Your App, your possibilites!
       </h2>
       <p>
         There are almost zero restrictions on how you can implement you app to utilize the features of Webropol. Create surveys and events directly from your applications and send them to your audience. Integrating Webropol to your application(s) will automate otherwise time-consuming manual tasks and free time for others.
       </p>
       <p>
         Possibilities are as extensive as is your imagination. Try it out and get creative!
       </p>
       <div className="cta-order">
                          </div>
   </section>
    <section className="docs-section" id="authentication">
    < h2 className="section-heading">Authentication</h2>
      <p>To use our REST API, you will need to authenticate using your Webropol credentials to get the authentication token that you will need to use in each request. </p>
      <p>Use a POST request to <a href="https://rest.webropolsurveys.com/token">https://rest.webropolsurveys.com/token</a> method for getting your authentication token. Parameters for this request are grant_type, username and password. Usage for this request is shown below.</p>
      <Codeblock codeName="getToken" />
      <p>You wil get a response message containing the token that is needed for authentication</p>
      <Codeblock codeName="tokenResponse" />
      <p>If using Postman for testing, the setup would look like this. </p>
      <Image src="../images/webropol_rest_auth.png" alt="Authentication With Postman" fluid />
    </section>
    <section className="docs-section" id="ids">
      <h2 className="section-heading">Unique Identifiers needed for using the API</h2>
      <strong>SurveyID, EventID</strong>
      <p>
     Each survey (and event) has its own globally unique identifier (GUID) that is represented in an 32 digits with hyphens. Most of the requests will need the survey id in order to work. Your survey id can be found from the URL address when accessing the survey via UI. You can also get all your surveys with their id's by using the request <a href="https://rest.webropolsurveys.com/swagger/ui/index#!/Surveys/Surveys_GetSurveys" target="_blank">/api/v1/surveys</a> which will list all the surveys your account has access to.
      </p>
      <strong>QuestionID, QuestionOptionID</strong>
      <p>Each survey contains of questions which, too, have an unique identifier, a QuestionID. Each question option also has it's own identifier, QuestionOptionID. These are used in several requests and you'll see those in request responses for example when using for example <a href="https://rest.webropolsurveys.com/swagger/ui/index#!/EventQuestions/EventQuestions_GetQuestions_0">/api/v1/surveys/SurveyID/questions</a></p>
      <strong>RecipientID, ResponseID</strong>
      <p>When retrieving survey or event responses or while handling survey or event recipients, you will also see RecipientID's and ResponseID's.</p>
    </section>
    </div>
     );
   }
 }

 export default GettingStarted