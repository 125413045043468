import React from 'react';
import { Nav, Container, Badge} from 'react-bootstrap';
import ApiStatus from '../common/ApiStatus'

class LeftNav extends React.Component {

   render() {

     return (
      <Container>
		  <Nav defaultActiveKey="/home" className="flex-column">
			<Nav.Item className="nav-header">
				<Nav.Link href="/status">API Status</Nav.Link>
				<ApiStatus  />
			</Nav.Item>
			<Nav.Item className="nav-header">
				<Nav.Link href="docs/#section-intro">Introduction</Nav.Link>
			</Nav.Item>
			<Nav.Item>
				<Nav.Link href="docs/#getting-started">Getting Started</Nav.Link>
			</Nav.Item>
			<Nav.Item>
				<Nav.Link href="docs/#authentication">Authentication</Nav.Link>
			</Nav.Item>
			<Nav.Item>
				<Nav.Link href="docs/#ids">Unique Identifier (ID's)</Nav.Link>
			</Nav.Item>
			<Nav.Item>
				<Nav.Link href="https://documenter.getpostman.com/view/6929240/TVejhqBv">All Requests & Examples</Nav.Link>
			</Nav.Item>
			<Nav.Item className="nav-header">
				<Nav.Link href="docs/#use-cases">Use Cases</Nav.Link>
			</Nav.Item>
			<Nav.Item>
				<Nav.Link href="docs/#item-cases-answers">Get Survey Answers</Nav.Link>
			</Nav.Item>
			<Nav.Item>
				<Nav.Link href="docs/#item-cases-create">Create a Survey/Event</Nav.Link>
			</Nav.Item>
			<Nav.Item>
				<Nav.Link href="docs/#item-cases-publish">Publish Survey</Nav.Link>
			</Nav.Item>
			<Nav.Item className="nav-header">
				<Nav.Link href="docs/#common-errors">Common Errors</Nav.Link>
			</Nav.Item>
			<Nav.Item>
				<Nav.Link href="docs/#unauthorized">Unauthorized</Nav.Link>
			</Nav.Item>
			<Nav.Item>
			  <Nav.Link href="docs/#not-found">404 Not Found</Nav.Link>
		  </Nav.Item>
		  <Nav.Item>
			  <Nav.Link href="docs/#internal-server-error">500 Internal Server Error</Nav.Link>
		  </Nav.Item>
		  <Nav.Item className="nav-header">
			  <Nav.Link href="#faq">FAQ</Nav.Link>
		  </Nav.Item>
		  </Nav>

		</Container>

     );
   }
 }

 export default LeftNav