import React from 'react';
import { Row, Col } from 'react-bootstrap';

class Introduction extends React.Component {

   render() {
     return (
      <article className="docs-article" id="section-intro">
         <section className="docs-intro">
               <Row>
               <Col>
               <p>
                     The Webropol API (Application Programming Interface) is a REST based service that offers a layer for connecting to the Webropol software. In very brief, REST stands for Representational State Transfer that is an architechtural pattern describing how systems can interact. When talking about "REST API" we generally refer to an API that can be accessed via HTTP requests at a set of predefined URL endpoints.
                  </p>
                  <p>
                     These endpoints represent underlying resources that can be accessed at said location using GET, PUT, POST, PATCH and DELETE methods. The request response will then be returned in JSON if not otherwise specified.
                  </p>
               </Col>
               </Row>
         </section>
      </article>
     );
   }
 }

 export default Introduction