import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';

class Navigation extends React.Component {
   constructor(props) {
     super(props);
     this.state = {
        isActive: "navbar nav d-flex justify-content-center"
     }
   }

   componentDidMount() {
      window.addEventListener('scroll', () => {
         if(window.scrollX > 50) {
          this.setState.isActive(" top-nav-collapse" );

         }

      })
   }

   render() {

     return (
        <div>

            <div className={this.state.isActive}>

                  <Navbar collapseOnSelect expand="lg" sticky="top">
                     <Navbar.Brand href="/">
                     <img
                        alt=""
                        src="/images/webropol_symbol.png"
                        className="d-inline-block align-top"
                        />{' '}
                     </Navbar.Brand>
                     <Navbar.Toggle aria-controls="basic-navbar-nav" />
                     <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                           <Nav.Link href="/">Home</Nav.Link>
                           <Nav.Link href="/#apis">API's</Nav.Link>
                           <Nav.Link href="/#what">Use Cases</Nav.Link>
                           <Nav.Link href="/#contact">Contact Us</Nav.Link>
                           <Nav.Link href="/docs">Docs</Nav.Link>
                           <Nav.Link href="https://documenter.getpostman.com/view/6929240/TVejhqBv#intro" target="_blank">Examples</Nav.Link>
                           <Nav.Link href="/terminology">Terminology</Nav.Link>
                           <Nav.Link href="/status">Status</Nav.Link>
                        </Nav>
                     </Navbar.Collapse>
                  </Navbar>
            </div>
       </div>

     );
   }
 }

 export default Navigation