import React from 'react';
import { Col, Row, Container, Form, Button, Alert } from 'react-bootstrap';

class Contact extends React.Component {
   constructor() {
      super();
      this.state = {
         isSent: "hidden",
         message: "Your message is highly appreciated <3",
         email: "your@email.com",
         name: "Your Name"
      };

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
   }

   handleChange(event) {

      this.setState({
         [event.target.name]: event.target.value
      });
   }

   handleSubmit(event) {
      console.log(this.state);
      event.preventDefault();

      const templateId = 'template_d5K8GHNj';

      this.sendFeedback(templateId, {
         message_html: this.state.message,
         from_name: this.state.name,
         reply_to: this.state.email,
      })
   }

   resetForm() {
      this.setState({name: '', email:'', message: ''})
   }

   sendFeedback (templateId, variables) {
      window.emailjs.send(
        'gmail', templateId,
        variables
        ).then(res => {
          console.log('Email successfully sent!')
          this.setState({isSent: "visible"});
          console.log("IsSent", this.state.isSent);
          this.resetForm();
        })
        .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
     }

   render() {
     return (
        <section id="contact" className="d-flex justify-content-center">

           <Container>

              <Row>
                 <Col>
                     <h1 className="section-title">Get in Touch - Contact us</h1>
                 </Col>
              </Row>
              <Row>
                 <Col md={8}>
                 <Alert variant="success" className={this.state.isSent}>
                 Thank you for your input, we'll be in touch!
               </Alert>
                  <Form onSubmit={this.handleSubmit}>
                     <Form.Group controlId="contactForm.Email">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control name="email" type="email" value={this.state.email} onChange={this.handleChange}/>
                        <Form.Text className="text-muted">
                           Psst! We'll never share your email with anyone.
                        </Form.Text>
                     </Form.Group>
                     <Form.Group controlId="contactForm.Name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control name="name" type="name" value={this.state.name} onChange={this.handleChange}/>
                     </Form.Group>
                     <Form.Group controlId="contactForm.Text">
                        <Form.Label>Your message</Form.Label>
                        <Form.Control name="message" as="textarea" rows="3" value={this.state.message} onChange={this.handleChange}/>
                     </Form.Group>
                  <Button variant="primary" type="submit" value="Submit">
                     Submit
                  </Button>
                  </Form>
                 </Col>
              </Row>
           </Container>
       </section>
     );
   }
 }

 export default Contact