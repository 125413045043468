import React from 'react';
import { Row, Col } from 'react-bootstrap';

class Header extends React.Component {

   render() {
     return (
        <div id="header">
         <section id="home" data-stellar-background-ratio="0.5" className="d-flex justify-content-center">
            <div className="overlay"></div>
                  <Row>
                     <Col className="home-info">
                        <h3>Let's integrate</h3>
                        <h1>Webropol API's</h1>
                        <a
                          href="/docs"
                          className="btn btn-primary section-btn geet-started smoothScroll"
                          type="submit">Read the Docs
                        </a>
                     </Col>
                  </Row>
         </section>
       </div>
     );
   }
 }

 export default Header