import React from 'react';
import { Row, Col, Container, Card, Button } from 'react-bootstrap';


class GetStarted extends React.Component {
   render() {
     return (
        <section className="apis" id="apis">
         <Container>
               <Row>
                  <Col>
                     <h1 className="section-title">Get Started - Explore our API's</h1>
                  </Col>
               </Row>
               <Row>
                  <Col>
                  <p>
                    With our APIs you can do all the basic features that can be done via the User Interface as well. Get to know our APIs by checking out the endpoints below or read <a href="/docs">the Docs section</a> for additional information.
                  </p>
                  <h2>REST or SOAP or both?</h2>
                  <p>Our SOAP API has been available for our customers since 2014. We are still 100% supporting it and it works like a charm, but it is no longer actively updated with new features. Hence, if just starting with our API's, we recommend starting with our new REST API which we are constantly updating with new features.</p>
                  <p>SOAP and REST are two different styles approaching data transmission. SOAP is a standardized, somewhat strict protocol sending messages using for example HTTP and SMTP. REST is not a protocol but an architectural style that was initially created to address the issues SOAP had. REST has a bit more flexible architechture and it is more lightweight which leads to usually better performing services.</p>
                  </Col>

               </Row>
               <Row>
                  <Col>
                  <Card className="shadow">
                     <Card.Img variant="top" src="images/051-api.png"/>
                        <Card.Body>
                           <Card.Title>Webropol REST V1</Card.Title>
                              <Card.Text>
                              RESTful API using JSON format
                              </Card.Text>
                              <span className="d-flex justify-content-center">
                                 <Button variant="primary" href="https://documenter.getpostman.com/view/6929240/TVejhqBv#intro" target="_blank">Explore</Button>
                              </span>
                        </Card.Body>
                     </Card>
                  </Col>
                  <Col>
                     <Card className="shadow">
                     <Card.Img variant="top" src="images/051-cloud-computing.png"/>
                        <Card.Body>
                           <Card.Title>Webropol SOAP DataService</Card.Title>
                              <Card.Text>
                              SOAP using XML format
                              </Card.Text>
                              <span className="d-flex justify-content-center">
                                 <Button variant="primary" href="https://integration.webropolsurveys.com/WebropolServices/MainWS/DataService.asmx" target="_blank">Explore</Button>
                              </span>
                        </Card.Body>
                     </Card>
                  </Col>
                  <Col>
                  <Card className="shadow">
                        <Card.Img variant="top" src="images/051-computer.png"/>
                        <Card.Body>
                           <Card.Title>Webropol SOAP MailService</Card.Title>
                              <Card.Text>
                              SOAP using XML format
                              </Card.Text>
                              <span className="d-flex justify-content-center">
                                 <Button variant="primary" href="https://integration.webropolsurveys.com/WebropolServices/MainWS/MailService.asmx" target="_blank">Explore</Button>
                              </span>
                        </Card.Body>
                     </Card>
                  </Col>
               </Row>
         </Container>
       </section>
     );
   }
 }

 export default GetStarted