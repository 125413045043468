import React from 'react';

const TableBody = (props) => {
  const { headers, rows } = props;
  const columns = headers ? headers.length : 0;
  const showSpinner = rows === null;
  const [sortedField, setSortedField] = React.useState(0);
  let sortedTerms = [...headers];
  if (sortedField !== null) {
    sortedTerms.sort((a, b) => {
      if (a[sortedField] < b[sortedField]) {
        return -1;
      }
      if (a[sortedField] > b[sortedField]) {
        return 1;
      }
      return 0;
    });
  }
  function buildRow(row, headers) {
    return (
         <tr key={row.term}>
         { headers.map((value, index) => {
             return <td key={index}>{row[value]}</td>
          })}
         </tr>
     )
  };

  return(
    <tbody>
        {showSpinner &&
          <tr key="spinner-0">
              <td colSpan={columns} className="text-center">
                  <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                  </div>
              </td>
          </tr>
          }
        { !showSpinner && rows && rows.map((value) => {
              return buildRow(value, headers);
          })}
    </tbody>
  );
}

export default TableBody;