import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Header from './common/Header'
import AppList from './status/AppList'
import Footer from './common/Footer'

class Monitor extends React.Component {

  render() {
    return (
        <div className="status">
          <Header />
            <Container>
              <section className="status-info">
                <Row >
                  <Col >
                    <h2 className="section-heading">Monitors for Webropol Internal Use</h2>
                    <h4>API Automation Test Monitors</h4>
                    <p>These Python+Pytest powered test cases run on <a href="https://docs.microsoft.com/en-us/azure/devops/pipelines/agents/agents?view=azure-devops&tabs=browser">Azure Pipeline Agents</a> daily.</p>
                    <h4>Robot Framework Automation Test Monitors</h4>
                    <p>These RobotFramework + Selenium based automation tests run on our own Jenkins server after each Staging and Production update. Monitors will send out emails for any failing events.</p>
                    <h4>NEW Automation Tests</h4>
                    <p>Currently working on a new dynamic set of automation tests that will be running on <a href="https://docs.microsoft.com/en-us/azure/devops/pipelines/agents/agents?view=azure-devops&tabs=browser">Azure Pipeline Agents</a> / Azure VM's.</p>
                  </Col>
                </Row>
              </section>
              <AppList />
            </Container>
            <Footer page="status"/>
          </div>


    );
  }

}

export default Monitor;
