
import React from 'react';
import Codeblock from '../common/Codeblock'

class CommonUse extends React.Component {

   render() {
     return (
      <article className="docs-article" id="use-cases">
					<section className="docs-intro">
							<h1>Common Use Cases</h1>
							<p>This section provides some common use cases and how to use our API for those. </p>
					</section>
					<section className="docs-section" id="item-cases-answers">
					  <h2 className="section-heading">Get Your Survey Answers</h2>
					  <p>You already have a survey with results but now want to export the answers to your own application? You can do that using our <a href="https://documenter.getpostman.com/view/6929240/TVejhqBv#4899e033-f6c9-4deb-b044-de38591fe7ea">get answer methods</a>. These methods can be used for fetching data for example to MS PowerBI or any other tool.</p>
						<p>An example call using curl for getting all the answers for a survey.</p>
						<Codeblock codeName="getResponses"/>
				  	</section>


				  <section className="docs-section" id="item-cases-create">
					  <h2 className="section-heading">Create a Survey and Add Questions</h2>
						<p>Want to automate creation of new surveys from your application? Use these methods for creating a new survey and adding questions to it.</p>
						<p>The request body contains the survey name and FolderId. Zero guid will create the survey into the root folder. Use <a href="https://documenter.getpostman.com/view/6929240/TVejhqBv#db047fea-d479-4e3d-9ff5-f7c1896892b3" >folder related methods</a> for adding new folders or getting the FolderId for alrealy existing ones.</p>
						<p>Add different question types to your survey using <a href="https://documenter.getpostman.com/view/6929240/TVejhqBv#9dac5571-164a-4554-9296-d9e0b7637372">question methods</a>.
						</p>
						<div className="callout-block callout-block-info">
							<div className="content">
								 <h4 className="callout-title">
									 <span className="callout-icon-holder mr-1">
								<i className="fas fa-info-circle"></i>
									</span>
									 Please note
								</h4>
								 <p>Please note the difference between the request body for different question types.  </p>
							</div>
					  </div>
					  <p>Currently supported question types include Selection, Multiselection and Contact Form. Support for other question types is under development. This example will create a selection question to the survey.</p>
				  </section>
				  <section className="docs-section" id="item-cases-publish">
					<h2 className="section-heading">Publish a Survey</h2>
					<p>Finished with the survey? Time to publish! Our API supports both publishing methods: public link and private link and additionally sending via SMS. This example demonstrates sending out the survey to some email recipients. </p>
					<p>First, add some recipients using <a href="https://documenter.getpostman.com/view/6929240/TVejhqBv#62ac9714-4a75-479b-8f67-b99b09b1aca9" target="_blank>">Add recipients method</a>. Recipient group can be left with a zero guid unless there are already recipient groups the recipient needs to be added to. This method supports adding multiple recipients. Other adding methods are available too.</p>
					<p>After adding recipients the survey is ready for sending out. The method supports different send out methods for "Filter": SendToAll, SendToNew, NotAnswered and Answered so you can use this method for sending reminders and thank you messages as well. </p>
					<div className="callout-block callout-block-success">
						<div className="content">
							 <h4 className="callout-title">
								 <span className="callout-icon-holder mr-1">
									<i className="fas fa-thumbs-up"></i>
								</span>
								 Pro Tip
							</h4>
							<p>You can create email templates currently via the user interface only. Create beautiful HTML templates and save them via Webropols UI and use them in your API calls.</p>
						</div>
					</div>
				</section>
      </article>
     );
   }
 }

 export default CommonUse