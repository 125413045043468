import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Header from '../components/common/Header'
import CommonErrors from '../components/docs/CommonErrors'
import Faq from '../components/docs/Faq'
import Introduction from '../components/docs/Introduction'
import LeftNav from '../components/docs/LeftNav'
import GettingStarted from '../components/docs/GettingStarted'
import CommonUse from './docs/CommonUse';
import Footer from '../components/common/Footer'

class Docs extends React.Component {

  render() {
    return (
        <div className="docs">
         <Header/>
          <Container>
             <Row>
               <Col md={3} className="left-nav">
                  <LeftNav/>
               </Col>
             <Col md={9} className="content">
               <Introduction/>
               <GettingStarted/>
               <CommonUse />
               <CommonErrors/>
               <Faq/>
             </Col>
             </Row>
          </Container>
        <Footer page="docs"/>
        </div>

    );
  }

}

export default Docs;
